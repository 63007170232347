// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/order/config.ts"
);
import.meta.hot.lastModified = "1731682775423.6418";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/orders',
  apiPath: '/api/order',
}

export enum OrderState {
  DRAFT = 'Draft',
  PAYMENT_SETTLED = 'PaymentSettled',
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  ARRANGING_PAYMENT = 'ArrangingPayment',
  ADDING_ITEMS = 'AddingItems',
  ARRANGING_ADDITIONAL_PAYMENT = 'ArrangingAdditionalPayment',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
}
